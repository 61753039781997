import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import NonStretchedImage from 'components/NonStretchedImage'

import styled, { themeGet } from 'util/style'
import { Flex, Box } from 'components/Grid'

import Layout from 'components/Layout'
import Content, { HTMLContent } from 'components/Content'
import SEO from 'components/SEO'
import { Date } from 'components/News'
import { Title } from 'components/News/styles'

export const StyledContainer = styled(Box)`
  ${props =>
    props.color && `background-color: ${themeGet(props.color)(props)};`};
  color: ${themeGet('colors.textColor')};
`

export const Wrapper = styled(Flex)`
  max-width: 600px;
`

export const NewsItemTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  image,
  date,
}) => {
  const PostContent = contentComponent || Content

  return (
    <StyledContainer as="section" my={5}>
      <Wrapper mx="auto" flexDirection="column" px={[3, 0]}>
        <Box mb={4}>
          <NonStretchedImage fluid={image.childImageSharp.fluid} alt={title} />
        </Box>
        <Title as="h1">{title}</Title>
        <Date date={date} tags={tags} />
        <Box mt={3}>
          <PostContent content={content} />
        </Box>
      </Wrapper>
    </StyledContainer>
  )
}

NewsItemTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  image: PropTypes.object.isRequired,
  tags: PropTypes.array,
  date: PropTypes.string.isRequired,
}

NewsItemTemplate.defaultProps = {
  contentComponent: () => {},
  title: '',
  tags: [],
}

const NewsItem = ({ data }) => {
  const { markdownRemark: post } = data
  const { footerData = {}, headerData = {} } = data

  let hData = {}
  if (headerData.edges && headerData.edges.length > 0) {
    hData = headerData.edges[0].node.frontmatter
  }

  let fData = {}
  if (footerData.edges && footerData.edges.length > 0) {
    fData = footerData.edges[0].node.frontmatter
  }

  return (
    <Layout headerData={hData} footerData={fData}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <NewsItemTemplate
        content={post.html}
        contentComponent={HTMLContent}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        date={post.frontmatter.date}
      />
    </Layout>
  )
}

NewsItem.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

NewsItem.defaultProps = {
  data: [],
}

export default NewsItem

export const pageQuery = graphql`
  query NewsItemByID($id: String!) {
    ...LayoutFragment
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
        description
        tags
      }
    }
  }
`
